import React from 'react';
import GlobalConfig from "../../shared/GlobalConfig";
import { useSelector} from "react-redux";
import { navigateOnDecline, openExternalLinkTab } from "../../helper/commonUtils";
import Button from "../Button/Button";
import translations from '../../translations';

const ErrorPage = ({ errorType = 'expiredLink' }) => {

  const { lang } = useSelector(state => state?.localization);
  const { region } = useSelector((state) => state?.appConfig);

  let textKey = "EXPIRED_TEXT";
  let subTextKey = "EXPIRED_SUBTEXT";

  if (errorType == 'invalidURL') {
    textKey = "INVALID_URL_TEXT";
    subTextKey = "INVALID_URL_SUBTEXT";
  } else if (errorType == 'systemUnavailable') {
    textKey = "SYSTEMUNAVAILABLE_MODAL_HEADER";
    subTextKey = "SYSTEMUNAVAILABLE_MODAL_BODY";
  }

  const onClickHandler = () => {
    const navigationURL = navigateOnDecline(lang, region);
    openExternalLinkTab(navigationURL);
  }

  const getKPLinkText = () => {
    const regionKey = region ? `_${region.toUpperCase()}` : '';
    const key = `KP_LINK_TEXT${regionKey}`;

    return GlobalConfig[key] || GlobalConfig.KP_LINK_TEXT;
  };

  return (
        <div className="vve-error-page-container">
          <div className='vve-error-logo'></div>
          <div className='expired-block'>
            <div className="block">
              <div><h1 className="link" aria-label={translations['en'][textKey]}>{translations['en'][textKey]}</h1></div>
              <div className="linkText">{translations['en'][subTextKey]}</div>
            </div>
            <div className='line'></div>
            <div className="block" lang="es">
            <div><h1 className="link" aria-label={translations['es'][textKey]}>{translations['es'][textKey]}</h1></div>
              <div className="linkText">{translations['es'][subTextKey]}</div>
            </div>
          </div>
          <div className="vve-button-div">
            <Button
              id="kpLinkBtn"
              modal=""
              //disabled=""
              tabIndex="0"
              refValue=""
              analyticsName=""
              onClick={onClickHandler}
              class="vve-modal-agree link"
            >
              <div className='btn-txt'>
              <div className="vve-text-underline">{getKPLinkText()}</div>
                <div className="arrow"></div>
              </div>
            </Button>
          </div>
        </div>
  )
}

export default ErrorPage;