import actionTypes from '../actionTypes';

const initialState = {
    os: null,
    type: null,
    launcherDetails: null,
    systemBrowserOsInfo: null
};

const DeviceDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DEVICE_DETAILS:
            return action.payload
        default:
            return state;
    }
};

export default DeviceDetailsReducer;