import translations from "../translations";
import Bowser from 'bowser';


export const navigateOnDecline = (language, region) => {
  const regionKey = region ? `_${region.toUpperCase()}` : '';
  const key = `REDIRECT_URL${regionKey}`;

  return translations[language.toLowerCase()][key] || translations[language.toLowerCase()]['REDIRECT_URL'];
};

export const getQueryString = (str) => {
  return  str?.split("?")[1];
};

const userAgent = navigator.userAgent;
const browser = Bowser.getParser(userAgent);

export const getDeviceDetails = () => {
  const deviceDetails = {
    os: null,
    type: null,
    systemBrowserOsInfo: null,
    orientation: null
  };
  deviceDetails.os = osDetector();
  deviceDetails.type = deviceType();
  deviceDetails.systemBrowserOsInfo = getBrowserOSInfo();
  return deviceDetails;
};

export const osDetector = () => {
  const osDetect = {
    iOS: 'ios',
    Android: 'aos',
    macOS: 'mac',
    'Windows Phone': 'windows',
    Windows: 'windows',
  };
  return osDetect[browser.getOSName()] || null;
};

export const deviceType = () => {
  return browser.getPlatformType() == "mobile" ? "mobile" : "desktop";
};

export const openExternalLinkTab = (url) => {
  window.location.replace(url);
};

export const zoomMeetingEvents = (zmMeeting, subscribe) => {
  //only support meeting
  zmMeeting.inMeetingServiceListener("onUserJoin", (data) => {
    console.log("onUserJoin", data);
  });

  zmMeeting.inMeetingServiceListener("onUserLeave", (data) => {
    console.log("onUserLeave", data);
  });

  zmMeeting.inMeetingServiceListener("onUserIsInWaitingRoom", (data) => {
    console.log("onUserIsInWaitingRoom", data);
  });

  zmMeeting.inMeetingServiceListener("onMeetingStatus", (data) => {
    console.log("onUserMeetingStatus", data);
    if (subscribe) {
      if (data.meetingStatus === 1) {
        subscribe("WAITING");
      }
      else if (data.meetingStatus === 2) {
        subscribe("STARTED");
      }
      else if (data.meetingStatus === 3) {
        subscribe("ENDED");
      }
    }
  });
};

export const getBrowserOSInfo = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);

  const osName = browser.getOSName();
  const osVersion = browser.getOSVersion();
  const browserName = browser.getBrowserName();
  const browserVersion = browser.getBrowserVersion();

  return {
    osName: osName || 'Unknown OS',
    osVersion: osVersion || 'Unknown Version',
    browserName: browserName || 'Unknown Browser',
    browserVersion: browserVersion || 'Unknown Version',
  };
};
