import axios from "axios";

const instance = axios.create({
	headers: {
		"Content-Type": "application/json"
	}
});
class ApiClient {
	static initAxiosConfig(configs) {
		this.config = configs;
	}
	static getInstance() {
		return instance;
	}
	static getClientServerUrl(launchType) {
		let CLIENT_BACKEND_BASE_URL = `/care/${launchType}/vve/`;
		const vveLibParams = window.__vveLibParams || null;
		if (vveLibParams) {
			const myChartFDIURL = new URL(vveLibParams.myChartFDIURL || "");
			const splitUrl = myChartFDIURL.pathname.split("/");
			CLIENT_BACKEND_BASE_URL = CLIENT_BACKEND_BASE_URL.replace(`${launchType}`, splitUrl[2]);
			CLIENT_BACKEND_BASE_URL = `${myChartFDIURL.origin}${CLIENT_BACKEND_BASE_URL}`;
		}
		console.log("CLIENT_BACKEND_BASE_URL", CLIENT_BACKEND_BASE_URL);
		return CLIENT_BACKEND_BASE_URL;
	}

	static getValidateUrl() {
		return this.config.bffServices.vveConferenceBff.srvUrlpath;
	}

	static getConsentUrl() {
		return this.config.bffServices.vveConsentBff.srvUrlpath;
	}

	static getValidateServerInstance() {
		const validateServices = this.config.bffServices.vveConferenceBff;
		const accessToken = this.config.tempToken.access_token;
		instance.defaults.timeout = validateServices.timeout || 8000;
		instance.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
		if (this.config.addenvlbl) {
			instance.defaults.headers['esb-envlbl'] = validateServices['esb-envlbl'];
		}
	}
	
	static getConsentServerInstance(pingToken) {
		const consentServices = this.config.bffServices.vveConsentBff;
		const accessToken = pingToken.access_token || '';
		instance.defaults.timeout = consentServices.timeout || 8000;
		instance.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
		if (this.config.addenvlbl) {
			instance.defaults.headers['esb-envlbl'] = consentServices['esb-envlbl'];
		}
	}

	static getRenewTokenUrl() {
		return this.config.bffServices.vveRenewTokenBff.srvUrlpath;
	}
}

export default ApiClient;