import apiClient from "../helper/apiClient";

export const getParamsForFetchConfig = (qs, url, launchType = null, region = null, visitType = null) => {
    if(launchType && region && visitType) {
        return {
            visitType: visitType.toUpperCase(),
            region: region.toUpperCase(),
            querystring: qs,
            myChartFDIURL: url,
            launchType: launchType.toUpperCase(),
        }
    }
    return null;
}

export const fetchConfigDetails = (launchType, visitType, region) => {
    const httpClient = apiClient.getInstance();
    const configEndPoint = `${apiClient.getClientServerUrl(launchType)}${region}/${visitType}/config`;
    return httpClient.get(configEndPoint);
}

export const fetchRenewTokenDetails = () => {
    const httpClient = apiClient.getInstance();
    const url = apiClient.getRenewTokenUrl();
    return httpClient.get(url);

}