import React from 'react';
import { Modal as ModalComponent, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const Modal = ({ id, openModal, closeModal, children, className, footerProps, backdrop }) => {

    const onOpened = () => {
        let element = document.getElementById(id);
        element.setAttribute("aria-modal", true);
        element.setAttribute("role", "dialog");
        element.focus();
    }

    return (
        <ModalComponent
            id={id}
            isOpen={openModal}
            toggle={closeModal}
            className={className}
            fade={true}
            backdrop={backdrop}
            onOpened={onOpened}
        >
            <ModalHeader toggle={closeModal}></ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
            <ModalFooter>
                {footerProps}
            </ModalFooter>
        </ModalComponent>
    )
}

export default Modal
