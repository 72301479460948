import actionTypes from "../actionTypes";
import apiClient from "../../helper/apiClient";

const APIConfigMiddleware = (store) => (next) => (action) => {
  if (action.type === actionTypes.FETCH_CONFIG_SUCCESS) {
    apiClient.initAxiosConfig(action.payload);
    apiClient.getValidateServerInstance();
  }
  if (action.type === actionTypes.FETCH_VALIDATE_SUCCESS || action.type === actionTypes.FETCH_RENEW_TOKEN_SUCCESS) {
    apiClient.getConsentServerInstance(action.payload.pingToken || {});
  }
  next(action);
};

export default APIConfigMiddleware;
