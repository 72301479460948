import { useState, useEffect } from "react";

const useOrientation = () => {
  const [orientation, setOrientation] = useState("");
  
  const getOrientation = () => {
    if (window.screen.orientation) {
      const { type } = window.screen.orientation;
      return type.includes('portrait') ? 'portrait' : 'landscape';
    } else if (typeof window.orientation !== "undefined") {
      switch (window.orientation) {
        case 0:
          return "portrait";
        case 90:
        case -90:
          return "landscape";
        default:
          return "Unknown";
      }
    }
    return "Not supported";
  };

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(getOrientation());
    };

    handleOrientationChange();

    if (window.screen.orientation && window.screen.orientation.addEventListener) {
      window.screen.orientation.addEventListener("change", handleOrientationChange);
    } else {
      window.addEventListener("orientationchange", handleOrientationChange);
    }

    return () => {
      if (window.screen.orientation && window.screen.orientation.removeEventListener) {
        window.screen.orientation.removeEventListener("change", handleOrientationChange);
      } else {
        window.removeEventListener("orientationchange", handleOrientationChange);
      }
    };
  }, []);

  return orientation;
};

export default useOrientation;
