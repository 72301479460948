import React from "react";
import { Outlet } from "react-router-dom";
import HeaderSection from "./Header/HeaderSection";
import AlertBanner from "../components/Banner/AlertBanner";

const Layout = ({ loading }) => {
  return (
    <>
      {!loading && <><HeaderSection /><AlertBanner /></>}
      <Outlet />
    </>
  );
};

export default Layout;
