import apiClient from '../helper/apiClient';
import GlobalConfig from '../shared/GlobalConfig';

export const postConsentApi = async (validateConfigData, status) => {
    const httpClient = apiClient.getInstance();
    const url = apiClient.getConsentUrl();
    let formData;

    formData = {
        firstName: validateConfigData?.user?.firstName,
        lastName: validateConfigData?.user?.lastName,
        consent: status,
        region: validateConfigData?.meetingInfo?.region,
        zoomMeetingId: validateConfigData?.meetingInfo?.meetingID.toString(),
        zoomUid: validateConfigData?.user?.uid,
        visitType: validateConfigData?.meetingInfo?.visitType,
        launchType: validateConfigData?.meetingInfo?.launchType,
        browserName: validateConfigData?.systemBrowserOsInfo?.browserName,
        browserVersion: validateConfigData?.systemBrowserOsInfo?.browserVersion,
        osName: validateConfigData?.systemBrowserOsInfo?.osName,
        osVersion: validateConfigData?.systemBrowserOsInfo?.osVersion
    }
    
    if (validateConfigData?.ambntlistngConsentStatus) {
        formData.ambntlistngConsent = validateConfigData.ambntlistngConsentStatus;
    }

    // Customize formData based on launchType
    if (validateConfigData?.meetingInfo?.launchType?.toUpperCase() === GlobalConfig.LAUNCH_VVEINVITE) {
        formData.inviteJoinURL = validateConfigData?.meetingInfo?.joinURL;
        formData.inviteId = validateConfigData?.user?.inviteId;
        delete formData.lastName;
        delete formData.zoomUid;
    }
     
    return httpClient.post(url, formData);
}